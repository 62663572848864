<template>
    <nav class="home-item">
        <div class="item-container" v-if="list.length">
            <div
                v-for="(item, index) in list"
                :key="index"
                class="item-card"
                @click="openPage(item.id)"
            >
                <figure>
                    <img :src="item[imgTag]" />
                    <div class="card-footer">
                        <div class="figcaption">
                            <slot v-bind:item="item"> {{ item[txtTag] }} </slot>
                        </div>
                    </div>
                </figure>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "home-item",
    props: {
        list: {
            type: Array,
            default: [],
        },
        imgBaseUrl: {
            type: String,
            default: "",
        },
        imgTag: {
            type: String,
            default: "",
        },
        txtTag: {
            type: String,
            default: "",
        },
        src: {
            type: String,
            default: "",
        },
        webview: {
            type: String,
            default: "",
        },
    },
    components: {},
    data() {
        return {};
    },
    methods: {
        getCategoryId() {},
        openPage(id) {
            this.$emit("openDetail", id);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/_varibles";
@import "@/style/mixin";

.home-item {
    background-color: #fff;
    .item-container {
        @include wh(100%, auto);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 36px;

        .item-card {
            @include fj(center);
            width: 48%;
            text-align: center;
            margin-top: 20px;
            border-radius: 20px;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
            figure {
                width: 100%;
                img {
                    @include wh(100%, 234px);
                    border-radius: 20px 20px 0 0;
                    display: block;
                }
                .figcaption {
                    @include sc(20px, $--color-text-regular);
                    width: 100%;
                    height: auto;
                    padding: 14px 12px 0 24px;
                    white-space: normal;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    /*! autoprefixer: off */
                    -webkit-box-orient: vertical;
                    /* autoprefixer: on */
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    word-break: break-all; /*追加这一行代码*/
                }
            }
            .card-footer {
                padding-bottom: 24px;
            }
        }
    }
}
</style>