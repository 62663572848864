<template>
    <div class="activity-details">
        <activity-banner
            :item="details"
            :thumbsUp="thumbsUp"
            :collection="collection"
            :isLike="isLike"
            :isCollection="isCollection"
             @handleCollection="setActivityCollection"
            @handleLike="handleLike"
        >
            <activity-swiper
                imgTag="url"
                :swiperList="bannerList"
                :slidesPerView="1"
                :spaceBetween="0"
                radio="0"
            ></activity-swiper>
        </activity-banner>
        <div class="activity-content">
            <div class="activity-title">{{ details.activityTitle }}</div>
            <activity-card class="u-p-l-20 u-p-r-20">
                <div>
                    <van-row class="activity-list">
                        <van-col span="6" style="color: #666">活动日期</van-col>
                        <van-col span="18">{{ details.activityTime }}</van-col>
                    </van-row>
                    <van-divider hairline style="margin: 0" />
                </div>
                <div>
                    <van-row class="activity-list">
                        <van-col span="6" style="color: #666">活动时间</van-col>
                        <van-col span="18">{{ details.activityHour }}</van-col>
                    </van-row>
                    <van-divider hairline style="margin: 0" />
                </div>
                <div>
                    <van-row class="activity-list" @click="openPage">
                        <van-col span="6" style="color: #666">活动地址</van-col>
                        <van-col span="18" class="flex-between">
                            <div>{{ details.actAddress }}</div>
                            <div>></div>
                        </van-col>
                    </van-row>
                    <van-divider hairline style="margin: 0" />
                </div>
                <div>
                    <van-row class="activity-list">
                        <van-col span="6" style="color: #666">团队人数</van-col>
                        <van-col span="18"
                            >活动限制{{ details.actPeople }}人
                            <span class="list-appointment"
                                >（已预约{{ actPeopleAlready }}人）</span
                            >
                        </van-col>
                    </van-row>
                </div>
            </activity-card>

            <activity-card class="u-m-t-40">
                <van-tabs v-model="active" class="details-tabs">
                    <van-tab title="活动详情">
                        <div
                            class="details-content"
                            v-html="details.actText"
                        ></div>
                    </van-tab>
                    <van-tab title="相关推荐">
                        <div class="recommend-title u-m-b-20">相关推荐</div>
                        <div class="recommend-content">
                            <home-item
                                class="u-m-t-10 u-m-b-30"
                                :list="details.activities || []"
                                imgTag="logoUrl"
                                :src="'/activity/details'"
                                @openDetail="getDetailActivity"
                            >
                                <div class="card-content">
                                    <header>红色街区 初心始发</header>
                                    <figure>
                                        <div class="u-m-t-12">
                                            上海市黄浦区滨江
                                        </div>
                                        <div class="u-m-t-12">2021.8.26</div>
                                    </figure>
                                </div>
                            </home-item>
                        </div>
                    </van-tab>
                </van-tabs>
            </activity-card>
        </div>
        <footer-make
            :makeStatus="makeStatus"
            :thumbsUp="thumbsUp"
            :collection="collection"
            :isLike="isLike"
            :isFull="isFull"
            :isCollection="isCollection"
            @handleMake="
                () => (makeStatus ? setActivityReduce() : setActivitySave())
            "
            @handleCollection="setActivityCollection"
            @handleLike="handleLike"
        ></footer-make>
        <van-popup v-model="show" round class="popup-container" closeable>
            <div>
                <van-icon
                    name="invitation"
                    size="120"
                    :color="punchStatus ? '#B72E32' : '#999'"
                />
                <div class="popup-title u-m-t-20">
                    {{
                        punchStatus
                            ? "恭喜你，预约成功～"
                            : "很抱歉，预约失败～"
                    }}
                </div>
            </div>
        </van-popup>
        <float-ball :icon="'wap-home-o'" :src="'/home'"></float-ball>
        <van-popup v-model="followShow" round class="popup-container" closeable>
            <div>
                <van-image
                    width="120"
                    height="120"
                    src="http://oss.hkrin.com/userInfo/10/20211114094734qrcode_rw.jpg"
                />
                <div class="popup-title u-m-t-20">请先扫码关注微信公众号</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { Toast } from "vant";
import {
    getDetailActivity,
    setActivitySave,
    setActivityReduce,
    setActivityCollection,
    setCancelCollection,
    setThumbsUp,
    setCancelThumbsUp,
    getWeixinJssdk
} from "@/service";
import floatBall from "@/components/common/floatBall.vue";
import activitySwiper from "./components/swiper.vue";
import footerMake from "./components/footer-make.vue";
import homeItem from "./components/home-item.vue";
import activityBanner from "./components/activity-banner.vue";
import activityCard from "./components/activity-card.vue";
export default {
    components: {
        floatBall,
        footerMake,
        homeItem,
        activityBanner,
        activityCard,
        activitySwiper,
    },
    data() {
        return {
            show: false,
            punchStatus: false,
            active: 0,
            detailHtml: "123123213",
            details: {},
            collection: "",
            makeStatus: false,
            bannerList: [],
            actLongitude: "",
            actLatitude: "",
            isCollection: false,
            isLike: false,
            actPeopleAlready: "",
            positions: [],
            isFull: false,
            followShow: false,
        };
    },
    mounted() {
        const { id = "" } = this.$route.query;
        this.getDetailActivity(id);
        var u = navigator.userAgent;
        if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
            this.initWechat();
        }
    },
    methods: {
        async initWechat() {
            const { nonceStr, jsapi_ticket, signature, timestamp } =
                await getWeixinJssdk({ url: window.location.href });
            if (nonceStr) {
                wx.config({
                    debug: false,
                    appId: "wx5a5c6ad0371692ec",
                    timestamp: timestamp,
                    nonceStr: nonceStr,
                    signature: signature,
                    jsApiList: ["checkJsApi", "openLocation", "getLocation"],
                });
            }
        },
        async getDetailActivity(id) {
            const params = {
                pageNo: 1,
                pageSize: 1000,
                activityId: id,
            };
            const { data } = await getDetailActivity(params);
            this.details = data;
            this.thumbsUp = data.thumbsUp;
            this.collection = data.collection;
            this.isCollection = data.haveXihuan;
            this.isLike = data.haveDianzan;
            this.makeStatus = data.haveCanjia;
            this.actPeopleAlready = data.actPeopleAlready;
            this.actLongitude = data.actLongitude;
            this.actLatitude = data.actLatitude;
            this.positions = [
                Number(data.actLongitude),
                Number(data.actLatitude),
            ];
            this.bannerList = JSON.parse(data.indexUrl);
            this.isFull =
                data.actPeople == data.actPeopleAlready && !this.makeStatus;
            document.title = data.activityTitle;
        },
        async setActivitySave() {
            if (!this.$isWeixin) {
                this.followShow = true;
                return false;
            }

            try {
                const { id } = this.details;
                const params = {
                    activityId: id,
                };
                const { data } = await setActivitySave(params);
                console.log(data, "====data=====");
                this.actPeopleAlready = Number(this.actPeopleAlready) + 1;
                this.makeStatus = true;
                this.punchStatus = true;
                this.show = true;
            } catch (err) {
                Toast.fail(err.message || err);
            }
        },
        async setActivityReduce() {
            if (!this.$isWeixin) {
                this.followShow = true;
                return false;
            }

            try {
                const { id } = this.details;
                const params = {
                    activityId: id,
                };
                const { data } = await setActivityReduce(params);
                this.actPeopleAlready = Number(this.actPeopleAlready) - 1;
                this.makeStatus = false;
                Toast.success("预约取消成功");
            } catch (err) {
                Toast.fail(err.message || err);
            }
        },
        async setActivityCollection() {
            if (!this.$isWeixin) {
                this.followShow = true;
                return false;
            }

            try {
                const { id } = this.details;
                const params = {
                    linePonitId: id,
                    status: this.isCollection ? 0 : 1,
                };
                const { data } = this.isCollection
                    ? await setCancelCollection(params)
                    : await setActivityCollection(params);
                this.collection = this.isCollection
                    ? Number(this.collection) - 1
                    : Number(this.collection) + 1;
                this.isCollection = !this.isCollection;
                Toast.success(`活动收藏${this.isCollection ? "" : "取消"}成功`);
            } catch (err) {
                Toast.fail(err.message || err);
            }
        },
        async handleLike() {
            if (!this.$isWeixin) {
                this.followShow = true;
                return false;
            }
            
            try {
                const { id } = this.details;
                const params = {
                    linePonitId: id,
                    status: this.isLike ? 0 : 1,
                };
                const { data } = this.isLike
                    ? await setCancelThumbsUp(params)
                    : await setThumbsUp(params);
                this.thumbsUp = this.isLike
                    ? Number(this.thumbsUp) - 1
                    : Number(this.thumbsUp) + 1;
                this.isLike = !this.isLike;
                Toast.success(`活动点赞${this.isLike ? "" : "取消"}成功`);
            } catch (err) {
                Toast.fail(err.message || err);
            }
        },
        openPage() {
            // this.$wxsdk.getLocation();
            const { actAddress, actLongitude, actLatitude } = this.details;
            this.$wxsdk.openLocation({
                latitude: actLatitude,
                longitude: actLongitude,
                name: actAddress,
                address: actAddress,
            });
            // sessionStorage.setItem("positions", JSON.stringify(this.positions));
            // sessionStorage.setItem(
            //     "address",
            //     JSON.stringify([this.details.actAddress])
            // );
            // this.$nextTick(() => {
            //     this.$router.push("/maps");
            // });
        },
    },
};
</script>

<style lang="scss">
@import "@/style/mixin";
@import "@/style/_varibles";

.activity-details {
    .activity-content {
        padding: 32px 38px 140px 38px;
        .activity-title {
            @include sc(28px, $--color-text-regular);
            font-weight: 500;
            line-height: 40px;
        }
        .activity-list {
            @include wh(100%, 114px);
            @include sc(24px, $--color-text-regular);
            display: flex;
            align-items: center;
            .list-appointment {
                color: $--color-text-primary;
            }
        }
        .details-tabs {
            // padding-bottom: 50px;
        }
        .details-content {
            @include sc(24px, $--color-text-secondary);
            padding: 30px;
            background: #fff7f6;
            margin-top: 30px;
            overflow-x: hidden;
            img {
                max-width: 100%;
            }
        }
        .recommend-title {
            @include sc(28px, $--color-text-regular);
            padding-left: 40px;
        }
        .recommend-content {
            padding-bottom: 20px;
        }
    }
    .popup-container {
        @include fj(center);
        align-items: center;
        width: 676px;
        height: 460px;
        background: #ffffff;
        border-radius: 40px;
        text-align: center;
        .popup-title {
            @include sc(22px, $--color-text-regular);
        }
    }
}
</style>
