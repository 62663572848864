<template>
    <div class="footer-make">
        <div class="footer-icon" @click="handleLike">
            <van-icon
                :name="isLike ? 'like' : 'like-o'"
                class="u-m-r-20"
                size="20"
                :color="isLike ? '#b72e32' : '#231F20'"
            />
            {{ thumbsUp }}
        </div>
        <div class="footer-icon" @click="handleCollection">
            <van-icon
                :name="isCollection ? 'star' : 'star-o'"
                class="u-m-r-20"
                size="20"
                :color="isCollection ? '#b72e32' : '#231F20'"
            />
            {{ collection }}
        </div>
        <div
            :class="['footer-button', isFull ? 'active' : '']"
            @click="handleMake"
        >
            {{ isFull ? "预约已满" : makeStatus ? "取消预约" : "点击预约" }}
        </div>
    </div>
</template>

<script>
export default {
    name: "footer-make",
    props: [
        "thumbsUp",
        "collection",
        "makeStatus",
        "isCollection",
        "isLike",
        "isFull",
    ],
    data() {
        return {};
    },
    methods: {
        handleMake() {
            if (this.isFull) {
                return false;
            }
            this.$emit("handleMake");
        },
        handleCollection() {
            this.$emit("handleCollection");
        },
        handleLike() {
            this.$emit("handleLike");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin";
@import "@/style/_varibles";

.footer-make {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px 0 40px;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.17);
    padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
    padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
    .footer-icon {
        @include sc(28px, $--color-text-regular);
        display: flex;
        align-items: center;
    }
    .footer-button {
        @include sc(28px, #fff);
        width: 348px;
        height: 80px;
        background: #b72e32;
        border-radius: 40px;
        text-align: center;
        line-height: 80px;
        margin-left: 50px;
        &.active {
            background: rgb(189, 189, 189);
        }
    }
}
</style>