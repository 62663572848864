var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',[(_vm.isShow)?_c('div',{ref:"dragIcon",staticClass:"dragIcon",style:({
            left: _vm.left + 'px',
            top: _vm.top + 'px',
            width: _vm.itemWidth + 'px',
            height: _vm.itemHeight + 'px',
        }),on:{"touchstart":function($event){$event.stopPropagation();return _vm.handleTouchStart.apply(null, arguments)},"touchmove":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleTouchMove($event)},"touchend":function($event){$event.stopPropagation();return _vm.handleTouchEnd.apply(null, arguments)},"click":function () { return this$1.$router.push(this$1.src); }}},[_c('van-icon',{attrs:{"name":_vm.icon,"size":"28","color":"#fff"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }