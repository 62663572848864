<template>
    <div class="activity-header">
        <slot></slot>
        <footer>
            <div class="activity-header-tag">{{ actType(item.actType) }}</div>
            <div class="activity-header-icon">
                <div @click="handleLike">
                    <van-icon
                        :name="isLike ? 'like' : 'like-o'"
                        size="20px"
                        :color="isLike ? '#b72e32' : '#fff'"
                        class="u-m-r-10"
                    />
                    <span style="vertical-align: top; color: #fff">{{
                        thumbsUp
                    }}</span>
                </div>
                <div class="u-m-l-30" @click="handleCollection">
                    <van-icon
                        :name="isCollection ? 'star' : 'star-o'"
                        size="20px"
                        :color="isCollection ? '#b72e32' : '#fff'"
                        class="u-m-r-10"
                    />
                    <span style="vertical-align: top; color: #fff">{{
                        collection
                    }}</span>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: "activity-banner",
    props: ["item", "thumbsUp", "collection", "isCollection", "isLike"],
    components: {},
    data() {
        return {};
    },
    computed: {
        actType() {
            return function (type) {
                type = type.toString();
                switch (type) {
                    case "1":
                        return "演出";
                    case "2":
                        return "展览";
                    case "3":
                        return "讲座";
                    case "4":
                        return "培训";
                    case "5":
                        return "赛事";
                    case "6":
                        return "其他";
                }
            };
        },
    },
    mounted() {},
    methods: {
        handleCollection() {
            this.$emit("handleCollection");
        },
        handleLike() {
            this.$emit("handleLike");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin";
@import "@/style/_varibles";

.activity-header {
    @include wh(100%, 454px);
    position: relative;
    footer {
        @include fj(space-between);
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 80px;
        background: rgba(0, 0, 0, 0.2);
        padding: 0 38px;
        z-index: 1000;
        .activity-header-tag {
            @include sc(24px, #ccc);
            padding: 8px 30px;
            border-radius: 60px;
            background: $--color-text-primary;
        }
        .activity-header-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            div {
                @include sc(30px, #fff);
            }
        }
    }
}
</style>
