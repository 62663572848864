<template>
    <div class="swiper-container" ref="swiper">
        <div class="swiper-wrapper">
            <div
                class="swiper-slide"
                :style="{ height: `${height}px` }"
                v-for="(item, index) in swiperList"
                :key="index"
            >
                <van-image
                    width="100%"
                    height="100%"
                    :radius="radius || 0"
                    :src="item[imgTag]"
                />
            </div>
        </div>
    </div>
</template>

<script>
// import "@/plugins/swiper.min.js";
// import "@/style/swiper.min.css";

export default {
    name: "home-swiper",
    props: [
        "imgTag",
        "height",
        "slidesPerView",
        "spaceBetween",
        "swiperList",
        "radius",
    ],
    data() {
        return {};
    },
    mounted() {
        setTimeout(() => {
            new Swiper(this.$refs.swiper, {
                slidesPerView: Number(this.slidesPerView),
                spaceBetween: Number(this.spaceBetween),
                centeredSlides: true,
                loop: true,
                touchRatio: 2,
            });
        }, 100);
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.swiper-container {
    width: 100%;
    height: auto;
}
.swiper-slide {
    width: 100%;
    height: 454px;
    border-radius: 20px;
    text-align: center;
    background: rgb(141, 179, 70);
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(1);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
    transform: scale(1);
}
</style>
